import { Box, styled, Typography } from '@mui/material';
import { Drawer } from 'shared';
import { pxToRem } from 'utils/formatFont';

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '.MuiDrawer-paper': {
    top: 200,
    height: 'calc(100vh - 45%)',
    background: theme.palette.text.title,
    color: '#fff',
    width: 749,
  },
}));

export const StyledDot = styled(Box)(() => ({
  width: 18,
  height: 18,
  background: '#43CB7D',
  borderRadius: '50%',
  marginRight: pxToRem(16),
}));

export const StyledTitleText = styled(Typography)(({ name }) => ({
  fontFamily: 'Times New Roman',
  fontWeight: 700,
  fontSize: pxToRem(32),
  lineHeight: '133%',
  color: '#FFFFFF',
  textTransform: name === 'fct' ? 'uppercase' : 'capitalize',
}));

export const StyledLabel = styled(Typography)(({ theme, color }) => ({
  fontWeight: 400,
  fontSize: pxToRem(12),
  lineHeight: '150%',
  color: color || theme.palette.background.disabled,
}));

export const StyledRightTitle = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: pxToRem(12),
  lineHeight: '150%',
}));
export const StyledRightTitleValue = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: pxToRem(26),
  lineHeight: '133%',
  color: '#43CB7D',
}));

export const StyledDrawerConten = styled('div')(() => ({}));
