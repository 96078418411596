/* eslint-disable no-unused-expressions */
import { getNotificationsParams } from 'modules/Admin/services/notifications';
import { useInfiniteQuery } from 'react-query';

const useFetchNotifications = () => {
  const {
    data: results,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery(
    ['useFetchNotifications'],
    ({ pageParam = 1 }) => getNotificationsParams(pageParam),
    {
      getNextPageParam: (lastPage, pages) => {
        const nextPage = pages.length + 1;
        return lastPage.total >= nextPage ? nextPage : undefined;
      },
    }
  );

  return {
    notifications: results?.pages?.[0]?.results || [],
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
  };
};
export default useFetchNotifications;
