/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart as ReactBarChart,
  ResponsiveContainer,
  Rectangle,
  Text,
} from 'recharts';
import { pxToRem } from 'utils/formatFont';

// eslint-disable-next-line , react/prop-types
function RenderCustomBarLabel({ value }) {
  return (
    <text fill="#666" textAnchor="middle" dy={-6}>{`value: ${value}`}</text>
  );
}

// eslint-disable-next-line no-unused-vars
function RenderCustomizedLabel(props) {
  // eslint-disable-next-line no-unused-vars, react/prop-types
  const { x, y, width, height, value, name } = props;
  // const radius = 10;

  return (
    <g>
      {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" /> */}
      <Text
        x={x + width / 2}
        y={y}
        fill="#fff"
        textAnchor="end"
        dominantBaseline="bottom"
        verticalAnchor="middle"
        angle={270}
        fontSize={pxToRem(14)}
        lineHeight={1.5}
        style={{
          margin: '20px',
        }}
      >
        {name}
      </Text>
    </g>
  );
}

function StackChart({
  data,
  keyArray,
  width,
  height,
  color,
  xDataKey,
  showLegend,
  xHide,
  tooltipContent,
  showXAxis,
  ...rest
}) {
  return (
    <ResponsiveContainer height={height} width={width}>
      <ReactBarChart
        // width={width}
        // height={height}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        stackOffset="sign"
        {...rest}
      >
        <CartesianGrid stroke="#f5f5f5" />
        {showXAxis && (
          <XAxis
            dataKey={xDataKey}
            hide={xHide}
            // angle="-90"
            mirror
            axisLine={false}
            tickMargin="-20"
            label={{ value: 'Index', angle: 0, position: 'insideLeft' }}
            tickLine={false}
          />
        )}

        <YAxis
          axisLine={false}
          fill="#999AAE"
          color="#999AAE"
          tickLine={false}
          label={{ content: <RenderCustomBarLabel /> }}
        />
        {showLegend && <Legend />}
        <Tooltip content={tooltipContent} />
        {keyArray &&
          keyArray?.length &&
          keyArray?.map((v, i) => (
            <Bar
              dataKey={v}
              label={{
                position: 'insideTop',
                angle: '-90',
                fill: '#fff',
                offset: 25,
                content: <RenderCustomizedLabel />,
              }}
              stackId="stack"
              fill={color[i % color.length]}
              shape={
                <Rectangle
                  radius={
                    keyArray?.length > 0 && keyArray.length - 1 === i
                      ? [8, 8, 0, 0]
                      : [0, 0, 0, 0]
                  }
                />
              }
            />
          ))}
      </ReactBarChart>
    </ResponsiveContainer>
  );
}

StackChart.propTypes = {
  data: PropTypes.array.isRequired,
  keyArray: PropTypes.array.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.array,
  xDataKey: PropTypes.string,
  showLegend: PropTypes.bool,
  xHide: PropTypes.bool,
  tooltipContent: PropTypes.element,
  showXAxis: PropTypes.bool,
};

StackChart.defaultProps = {
  width: 500,
  height: 300,
  color: [
    '#1f77b4',
    '#ff7f0e',
    '#2ca02c',
    '#d62728',
    '#9467bd',
    '#8c564b',
    '#e377c2',
    '#7f7f7f',
    '#bcbd22',
    '#17becf',
  ],
  xDataKey: 'name',
  showLegend: false,
  xHide: true,
  tooltipContent: null,
  showXAxis: false,
};

export default StackChart;

const getPath = (x, y, width, height) => {
  return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${
    y + height / 3
  } 
  ${x + width / 2}, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + width} ${
    x + width
  }, ${y + height}
  Z`;
};

function RoundedBar({ fill, x, y, width, height }) {
  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
}

RoundedBar.propTypes = {
  fill: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};
