import Api from 'utils/Api';

export const getAvailableUsers = async ({ queryKey, pageParam }) => {
  const [, params] = queryKey;
  const { data } = await Api.get(`auth/users/available-agents/`, {
    params: {
      ...params,
      page: pageParam ?? 1,
    },
  });
  return data;
};

export const getAvailableAgents = async ({ queryKey, pageParam }) => {
  const [, params] = queryKey;

  const { data } = await Api.get(`auth/users/available-agents/`, {
    params: {
      ...params,
      page: pageParam ?? 1,
      page_size: 5000,
    },
  });
  return data;
};
export const getAvailableLeadUsers = async ({ params }) => {
  const { data } = await Api.get(`auth/users/available-leads/`, { params });
  return data;
};

export const getUser = async (id) => {
  const { data } = await Api.get(`auth/users/${id}/`);
  return data;
};

export const getSlugInfo = async ({ slug }) => {
  const { data } = await Api.get(`/tenants/slug/${slug}/`);
  return data?.data;
};
