import React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import dot from 'assets/dot.svg';
// import infos from 'constant/notificationConstant';

function NotificationMenu({ data, onClick }) {
  function formatTime(timestamp) {
    const now = new Date();
    const diffMs = now - new Date(timestamp);
    const diffSeconds = Math.floor(diffMs / 1000);
    if (diffSeconds < 60) {
      return `${diffSeconds} seconds ago`;
    }
    const diffMinutes = Math.floor(diffSeconds / 60);
    if (diffMinutes < 60) {
      return `${diffMinutes} minutes ago`;
    }
    const diffHours = Math.floor(diffMinutes / 60);
    if (diffHours < 24) {
      return `${diffHours} hours ago`;
    }
    const diffDays = Math.floor(diffHours / 24);
    if (diffDays < 7) {
      return `${diffDays} days ago`;
    }
    const diffWeeks = Math.floor(diffDays / 7);
    if (diffWeeks < 4) {
      return `${diffWeeks} weeks ago`;
    }
    const diffMonths = Math.floor(diffDays / 30);
    if (diffMonths < 12) {
      return `${diffMonths} months ago`;
    }
    const diffYears = Math.floor(diffMonths / 12);
    return `${diffYears} years ago`;
  }
  const handleButtonClick = (id) => {
    onClick(id);
  };
  return (
    <Box
      sx={{
        height: '500px',
        mt: 13,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '6px',
          height: '8px',
          backgroundColor: '#C8C6C4',
        },
      }}
    >
      {data &&
        data?.map((info) => (
          <Box key={info.id}>
            <MenuItem onClick={() => handleButtonClick(info?.id)}>
              <Box sx={{ height: '50%', width: '100%' }}>
                {info?.is_read === false && <img src={dot} alt="new" />}
                <Stack
                  display="flex"
                  direction="row"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '600',
                      lineHeight: '18.62px',
                    }}
                  >
                    {info?.notification?.title || 'Title'}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: '400',
                    }}
                  >
                    {formatTime(info?.notification?.created_at)}
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: '400',
                    width: '100%',
                    whiteSpace: 'break-spaces',
                  }}
                >
                  {info?.notification?.message}
                  {/* {info?.sub} */}
                </Typography>
              </Box>
            </MenuItem>
            <Divider />
          </Box>
        ))}
    </Box>
  );
}
NotificationMenu.propTypes = {
  data: PropTypes.objectOf([]),
  onClick: PropTypes.func,
};

NotificationMenu.defaultProps = {
  data: [],
  onClick: () => {},
};

export default NotificationMenu;
