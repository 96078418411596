export const BasePaths = {
  ADMIN: `/admin`,
  SUPERADMIN: `/super-admin`,
  CALL_AGENT: `/lead-call-agent`,
  CALL_AGENT_INBOUND: `/inbound-call-agent`,
  CALL_AGENT_OUTBOUND: `/outbound-call-agent`,
  CAMPAIGN_MANAGER: `/campaign-manager`,
  CALL_GROUP_LEAD_OUTBOUND: `/call-group-lead-outbound`,
  CANDIDATE: `/candidate`,
  EXECUTIVE_USER: `/executive`,
  NATIONAL_EXECUTIVE_USER: `/national-executive`,
  SECURITY_MONITORING_ADMIN: `/security-monitoring-admin`,
  SITUATION_ROOM_ADMIN: `/situation-room-admin`,
  SITUATION_ROOM_NATIONAL: `/national-situation-room`,
  SITUATION_ROOM_STATE: `/state-situation-room`,
  SITUATION_ROOM: `/situation-room`,
};

export const AuthPaths = {
  SIGNIN: `signin`,
  SIGNUP: `signup`,
  FORGOT_PASSWORD: `forgot-password`,
  CREATE_PASSWORD: `create-password/`,
  RESET_PASSWORD: `reset-password`,
  NEW_USER: `login`,
  VERIFY_ACCOUNT: `verify-account`,
};

export const TermsPrivacyPath = {
  TERMS_CONDITIONS: `terms&conditions`,
  PRIVACY_POLICY: `privacy-policy`,
};

export const AdminPaths = {
  ADMIN_DASHBOARD: `/admin/dashboard`,
  ADMIN_CAMPAIGN: `/admin/campaign`,
  ADMIN_POLL: `/admin/poll`,
  ADMIN_CALL_GROUP: `/admin/call-group`,
  ADMIN_DIRECTORY: `/admin/directory`,
  ADMIN_ELECTORATE: `/admin/database/electorate`,
  ADMIN_USER_MANAGEMENT: `/admin/user-management`,
  ADMIN_VOTER: `/admin/voter`,
  ADMIN_SETTINGS: `/admin/settings`,
  ADMIN_VOTERS_360: `/admin/voters-360`,
  ADMIN_CANVASSER: `/admin/field-agents`,
  ADMIN_PARTY: `/admin/party`,
  ADMIN_SECURITY_REPORT: `/admin/security-report`,
  ADMIN_AGENT_MANAGEMENT: `/admin/agent-management`,
  ADMIN_INTELLIGENCE: `/admin/intelligence`,
  ADMIN_INCIDENCE: `/admin/incidence`,
  ADMIN_SITUATION_REPORT: `/admin/situation-report`,
  ADMIN_RESULT_MANAGEMENT: `/admin/election-results`,
  ADMIN_ACTIVITY_MANAGEMENT: `/admin/activity-management`,
};

export const SuperAdminPaths = {
  SUPER_ADMIN_INSTANCE: `/super-admin/instance`,
};

export const LeadCallGroupPaths = {
  CALL_GROUP_DASHBOARD: `/lead-call-agent/dashboard`,
  CALL_GROUP_WORK_STATION: `/lead-call-agent/workstation`,
  CALL_GROUP_AGENT: `/lead-call-agent/agents`,
  CALL_GROUP_LOG: `/lead-call-agent/logs`,
  CALL_GROUP_SECURITY_REPORT: `/lead-call-agent/security-report`,
};

export const InboundCallGroupPaths = {
  CALL_GROUP_DASHBOARD: `/inbound-call-agent/dashboard`,
  CALL_GROUP_WORK_STATION: `/inbound-call-agent/workstation`,
  CALL_GROUP_LOG: `/inbound-call-agent/log`,
  CALL_GROUP_SECURITY_REPORT: `/inbound-call-agent/security-report`,
};
export const outboundCallGroupPaths = {
  CALL_GROUP_DASHBOARD: `/outbound-call-agent/dashboard`,
  CALL_GROUP_WORK_STATION: `/outbound-call-agent/workstation`,
  CALL_GROUP_LOG: `/outbound-call-agent/logs`,
  CALL_GROUP_SECURITY_REPORT: `/outbound-call-agent/security-report`,
  CALL_GROUP_AGENTS: `/outbound-call-agent/agents`,
  CALL_GROUP_AGENT_MANAGEMENT: `/outbound-call-agent/agent-management`,

  CALL_GROUP_CANVASSER: `/outbound-call-agent/field-agents`,
  CALL_GROUP_PARTY: `/outbound-call-agent/party`,
  CALL_GROUP_DIRECTORY: `/outbound-call-agent/directory`,
};

export const CampaignManagerPaths = {
  CAMPAIGN_MANAGER_DASHBOARD: `/campaign-manager/dashboard`,
  // CAMPAIGN_MANAGER_WARROOM: `/campaign-manager/war-room`,
  // CAMPAIGN_MANAGER_WARROOM_STATE: `/campaign-manager/war-room-state`,
  CAMPAIGN_MANAGER_POLL: `/campaign-manager/poll`,
  CAMPAIGN_MANAGER_CALL_GROUP: `/campaign-manager/call-group`,
  CAMPAIGN_MANAGER_SITUATION_REPORT: `/campaign-manager/situation-report`,
  CAMPAIGN_MANAGER_ELECTION_RESULTS: `/campaign-manager/election-results`,
  CAMPAIGN_MANAGER_VOTERS_360: `/campaign-manager/voters-360`,
  CAMPAIGN_MANAGER_CANVASSER: `/campaign-manager/field-agents`,
  CAMPAIGN_MANAGER_PARTY: `/campaign-manager/party`,
  CAMPAIGN_MANAGER_DIRECTORY: `/campaign-manager/directory`,
  CAMPAIGN_MANAGER_ELECTORATE: '/campaign-manager/electorate',
  CAMPAIGN_MANAGER_SECURITY_REPORT: `/campaign-manager/security-report`,
  CAMPAIGN_MANAGER_INTELLIGENCE: `/campaign-manager/intelligence`,
  CAMPAIGN_MANAGER_INCIDENCE: `/campaign-manager/incidence`,
};

export const CallGroupLeadOutboundPaths = {
  CALL_GROUP_LEAD_DASHBOARD: `/call-group-lead-outbound/dashboard`,
  CALL_GROUP_LEAD_WORKSTATION: `/call-group-lead-outbound/workstation`,
  CALL_GROUP_LEAD_LOG: `/call-group-lead-outbound/logs`,
  CALL_GROUP_LEAD_SECURITY_REPORT: `/call-group-lead-outbound/security-report`,
  CALL_GROUP_LEAD_AGENTS: `/call-group-lead-outbound/agents`,
  CALL_GROUP_LEAD_CANVASSER: `/call-group-lead-outbound/field-agents`,
  CALL_GROUP_LEAD_PARTY: `/call-group-lead-outbound/party`,
  CALL_GROUP_LEAD_DIRECTORY: `/call-group-lead-outbound/directory`,
  CALL_GROUP_AGENT_MANAGEMENT: `/call-group-lead-outbound/agent-management`,
};

export const CadidatePaths = {
  CANDIDATE_DASHBOARD: `/candidate/dashboard`,
  CANDIDATE_POLL: `/candidate/poll`,
  CANDIDATE_SITUATION_REPORT: `/candidate/situation-report`,
  CANDIDATE_ELECTION_RESULTS: `/candidate/election-results`,
  CANDIDATE_INTELLIGENCE: `/candidate/intelligence`,
  CANDIDATE_INCIDENCE: `/candidate/incidence`,
  CANDIDATE_SECURITY_REPORT: `/candidate/security-report`,
  CANDIDATE_VOTERS_360: `/candidate/voters-360`,
  CANDIDATE_CANVASSER: `/candidate/field-agents`,
  CANDIDATE_PARTY: `/candidate/party`,
  CANDIDATE_DIRECTORY: `/candidate/directory`,
  CANDIDATE_ELECTORATE: '/candidate/electorate',
};

export const ExecutivePaths = {
  EXECUTIVE_DASHBOARD: `/executive/dashboard`,
  EXECUTIVE_DIRECTORY: `/executive/directory`,
  EXECUTIVE_SECURITY_REPORT: `/executive/security-report`,
};

export const NationalExecutivePaths = {
  NATIONAL_EXECUTIVE_DASHBOARD: `/national-executive/dashboard`,
  NATIONAL_EXECUTIVE_DIRECTORY: `/national-executive/directory`,
  NATIONAL_EXECUTIVE_SECURITY_REPORT: `/national-executive/security-report`,
};

export const SecurityMonitoringAdminPaths = {
  SECURITY_MONITORING_DASHBOARD: `/security-monitoring-admin/dashboard`,
  SECURITY_MONITORING_WARROOM: `/security-monitoring-admin/war-room`,
  SECURITY_MONITORING_INTELLIGENCE: `/security-monitoring-admin/intelligence`,
  SECURITY_MONITORING_INCIDENCE: `/security-monitoring-admin/incidence`,
  SECURITY_MONITORING_SECURITY_REPORT: `/security-monitoring-admin/security-report`,
  SECURITY_MONITORING_DIRECTORY: `/security-monitoring-admin/directory`,
  SECURITY_MONITORING_AGENTS: `/security-monitoring-admin/agents`,
  SECURITY_MONITORING_PARTY: `/security-monitoring-admin/party`,
};

export const SituationRoomAdminPaths = {
  SITUATION_ROOM_DASHBOARD: `/situation-room-admin/dashboard`,
  SITUATION_ROOM_SITUATION_REPORT: `/situation-room-admin/situation-report`,
  SITUATION_ROOM_SECURITY_REPORT: `/situation-room-admin/security-report`,
  SITUATION_ROOM_RESULT_MANAGEMENT: `/situation-room-admin/election-results`,
  SITUATION_ROOM_DIRECTORY: `/situation-room-admin/directory`,
  SITUATION_ROOM_AGENTS: `/situation-room-admin/agents`,
  SITUATION_ROOM_PARTY: `/situation-room-admin/party`,
  SITUATION_ROOM_WARROOM: `/situation-room-admin/war-room`,
  SITUATION_ROOM_INTELLIGENCE: `/situation-room-admin/intelligence`,
  SITUATION_ROOM_INCIDENCE: `/situation-room-admin/incidence`,
};

export const SituationRoomPaths = {
  SITUATION_ROOM_WARROOM: `/situation-room-admin/war-room`,
};
