import { lazy } from 'react';

import {
  ADMIN_SIDE_NAVS,
  CALL_GROUP_AGENT_SIDE_OUTBOUND_NAVS,
  CALL_GROUP_AGENT_SIDE_INBOUND_NAVS,
  CALL_GROUP_LEAD_SIDE_NAVS,
  CAMPAIGN_MANAGER_SIDE_NAVS,
  CALL_GROUP_LEAD_OUTBOUND_SIDE_NAVS,
  NATIONAL_EXECUTIVE_SIDE_NAVS,
  SECURITY_MONITORING_ADMIN_SIDE_NAVS,
  SITUATION_ROOM_ADMIN_SIDE_NAVS,
  // SITUATION_ROOM_SIDE_NAVS,
} from 'constant/sidenav';

import { Layout as MainLayout } from 'shared';

import { BasePaths } from './paths';

const BaseRoutes = [
  {
    path: '/*',
    exact: true,
    component: lazy(() => import('modules/Auth/AuthRouter')),
    Layout: false,
    useAuth: false,
  },
  {
    path: `${BasePaths.ADMIN}/*`,
    exact: true,
    component: lazy(() => import('modules/Admin/routes')),
    Layout: MainLayout,
    useAuth: true,
    sidenavItems: ADMIN_SIDE_NAVS,
  },
  {
    path: `${BasePaths.SUPERADMIN}/*`,
    exact: true,
    component: lazy(() => import('modules/SuperAdmin/routes')),
    Layout: MainLayout,
    useAuth: true,
    sidenavItems: null,
  },
  {
    path: `${BasePaths.CALL_AGENT}/*`,
    exact: true,
    component: lazy(() => import('modules/CallGroupAgent/routes')),
    Layout: MainLayout,
    useAuth: true,
    sidenavItems: CALL_GROUP_LEAD_SIDE_NAVS,
  },
  {
    path: `${BasePaths.CALL_AGENT_INBOUND}/*`,
    exact: true,
    component: lazy(() => import('modules/InboundCallGroupAgent/routes')),
    Layout: MainLayout,
    useAuth: true,
    sidenavItems: CALL_GROUP_AGENT_SIDE_OUTBOUND_NAVS,
  },
  {
    path: `${BasePaths.CALL_AGENT_OUTBOUND}/*`,
    exact: true,
    component: lazy(() => import('modules/OutboundCallGroupAgent/routes')),
    Layout: MainLayout,
    useAuth: true,
    sidenavItems: CALL_GROUP_AGENT_SIDE_INBOUND_NAVS,
  },
  {
    path: `${BasePaths.CAMPAIGN_MANAGER}/*`,
    exact: true,
    component: lazy(() => import('modules/CampaignManager/Routes')),
    Layout: MainLayout,
    useAuth: true,
    sidenavItems: CAMPAIGN_MANAGER_SIDE_NAVS,
  },
  {
    path: `${BasePaths.CALL_GROUP_LEAD_OUTBOUND}/*`,
    exact: true,
    component: lazy(() => import('modules/CallGroupLeadOutbound/routes')),
    Layout: MainLayout,
    useAuth: true,
    sidenavItems: CALL_GROUP_LEAD_OUTBOUND_SIDE_NAVS,
  },
  {
    path: `${BasePaths.CALL_GROUP_LEAD_OUTBOUND}/workstation/view-polls/unassigned`,
    exact: true,
    component: lazy(() =>
      import(
        'modules/CallGroupLeadOutbound/pages/WorkStation/ViewUnassignedPolls'
      )
    ),
    // Layout: MainLayout,
    useAuth: true,
    sidenavItems: CALL_GROUP_LEAD_OUTBOUND_SIDE_NAVS,
  },
  {
    path: `${BasePaths.CANDIDATE}/*`,
    exact: true,
    component: lazy(() => import('modules/Candidate/Routes')),
    Layout: MainLayout,
    useAuth: true,
    sidenavItems: CAMPAIGN_MANAGER_SIDE_NAVS,
  },
  {
    path: `${BasePaths.NATIONAL_EXECUTIVE_USER}/*`,
    exact: true,
    component: lazy(() => import('modules/NationalExecutive/Routes')),
    Layout: MainLayout,
    useAuth: true,
    sidenavItems: NATIONAL_EXECUTIVE_SIDE_NAVS,
  },
  {
    path: `${BasePaths.EXECUTIVE_USER}/*`,
    exact: true,
    component: lazy(() => import('modules/Executive/Routes')),
    Layout: MainLayout,
    useAuth: true,
    sidenavItems: CAMPAIGN_MANAGER_SIDE_NAVS,
  },
  {
    path: `${BasePaths.SECURITY_MONITORING_ADMIN}/*`,
    exact: true,
    component: lazy(() => import('modules/SecurityMonitoringAdmin/routes')),
    Layout: MainLayout,
    useAuth: true,
    sidenavItems: SECURITY_MONITORING_ADMIN_SIDE_NAVS,
  },
  {
    path: `${BasePaths.SITUATION_ROOM_ADMIN}/*`,
    exact: true,
    component: lazy(() => import('modules/SituationRoomAdmin/routes')),
    Layout: MainLayout,
    useAuth: true,
    sidenavItems: SITUATION_ROOM_ADMIN_SIDE_NAVS,
  },
  {
    path: `${BasePaths.SITUATION_ROOM_NATIONAL}/*`,
    exact: true,
    component: lazy(() => import('modules/CampaignManager/Pages/WarRoom')),
    Layout: MainLayout,
    useAuth: true,
    sidenavItems: SITUATION_ROOM_ADMIN_SIDE_NAVS,
  },

  {
    path: `${BasePaths.SITUATION_ROOM_STATE}/*`,
    exact: true,
    component: lazy(() =>
      import('modules/CampaignManager/Pages/WarRoom-State')
    ),
    Layout: MainLayout,
    useAuth: true,
    sidenavItems: SITUATION_ROOM_ADMIN_SIDE_NAVS,
  },
  // {
  //   path: `${BasePaths.SITUATION_ROOM}/*`,
  //   exact: true,
  //   component: lazy(() => import('modules/SituationRoom/routes')),
  //   Layout: MainLayout,
  //   useAuth: true,
  //   sidenavItems: SITUATION_ROOM_SIDE_NAVS,
  // },
];

export default BaseRoutes;
