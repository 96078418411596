import Api from 'utils/Api';

export const getNotificationsParams = async (pageParam) => {
  const { data } = await Api.get(
    `/notification/?page=${pageParam}&page_size=10`
  );
  return data;
};

export const updateNotificationReadStatus = async (params) => {
  const { data } = await Api.post('/notification/mark-as-read/', params);
  return data;
};
