/* eslint-disable react/forbid-prop-types */
/* eslint-disable prefer-template */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as d3 from 'd3';
import geojson from 'data/states.geo.json';
import StateDetailsDrawer from './StateDetailsDrawer/StateDetailsDrawer';

const pathGenerator = ([width, height], geojson1) => {
  const projection = d3.geoMercator().fitSize([width, height], geojson1);
  return d3.geoPath().projection(projection);
};
// const fillColours = ['#FFF3DB', '#EDF9F0', '#FEEFEF', '#EBEBEB'];

function Map({ width, height, records }) {
  const [paths, setPaths] = useState([]);
  const [selectedPath, setSelectedPath] = useState(null);
  const [openCard, setOpenCard] = useState(false);

  const emptyState = {
    name: 'No value found',
    state: 'No state selected',
    records: [],
  };
  const handlePathClick = (name, admin1Pcod) => {
    if (records && records?.length > 0) {
      const foundRecords = records.find((v) => v.stateCode === admin1Pcod);
      if (foundRecords) {
        setSelectedPath(foundRecords);
      } else {
        setSelectedPath(emptyState);
      }
    } else {
      setSelectedPath(emptyState);
    }
    setOpenCard(true);
  };

  useEffect(() => {
    const getPath = pathGenerator([width, height], geojson);
    const Rpaths = geojson.features.map((feature) => {
      const { properties, geometry } = feature;
      return { ...properties, path: getPath(geometry) };
    });
    setPaths(Rpaths);
  }, [width, height]);

  return (
    <>
      <div>
        <Svg width={width} height={height}>
          {paths.map(({ name, path, admin1Pcod }, index) => (
            <Path
              fill="#c1c1c1"
              key={index}
              onClick={() => handlePathClick(name, admin1Pcod)}
              d={path}
            />
          ))}
        </Svg>
        <Box position="relative" width="100%">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="absolute"
            bottom={0}
            right={0}
            width="50%"
          >
            {selectedPath?.records &&
              selectedPath?.records?.length > 0 &&
              selectedPath?.records?.map((v) => (
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <StyledDonut />
                  {v?.name || '--'}
                </Box>
              ))}
          </Box>
        </Box>
      </div>

      <StateDetailsDrawer open={openCard} onClose={() => setOpenCard(false)} />
    </>
  );
}

Map.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  records: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      records: PropTypes.array.isRequired,
      stateCode: PropTypes.string.isRequired,
    })
  ),
};

Map.defaultProps = {
  width: 700,
  height: 600,
  records: null,
};

const Svg = styled('svg')((props) => ({
  viewBox: `0 0 ` + props.width + ` ` + props.height,
  preserveAspectRatio: `xMidYMid meet`,
  width: props.width,
  height: props.height,
}));

const StyledDonut = styled('span', {
  shouldForwardProp: (prop) => prop !== 'stateColor',
})(({ theme, stateColor }) => ({
  display: 'block',
  width: 12,
  height: 12,
  border: '2px solid #DA1414',
  borderRadius: '50%',
  background: theme.palette.common.white,
  ...(stateColor && {
    border: `2px solid ${stateColor}`,
  }),
  marginRight: '8px',
}));

const Path = styled('path')((props) => ({
  cursor: 'pointer',
  fill: props.fill,
  stroke: '#fff',
  '&:hover': {
    fill: 'lightgreen',
  },
  '&:active': {
    opacity: 0.8,
  },
}));

export default Map;
