/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  BarChart as ReactBarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Rectangle,
} from 'recharts';

// eslint-disable-next-line no-unused-vars
const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
  return (
    <text
      x={x + width / 2}
      y={y}
      fill="#666"
      textAnchor="middle"
      dy={-6}
    >{`value: ${value}`}</text>
  );
};
function BiDirectionalBarChart({
  data,
  keyArray,
  width,
  height,
  color,
  xDataKey,
  showLegend,
  xHide,
  tooltipContent,
  legendContent,
  yAxisFormater,
  xAxisFormater,
  singleKey,
  showYLabel,
  showTooltip,
  showReferenceline,
  ...rest
}) {
  return (
    <ResponsiveContainer height={height} width={width}>
      <ReactBarChart
        width={width}
        height={height}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        {...rest}
      >
        <CartesianGrid strokeDasharray="0  6" />
        <XAxis
          dataKey={xDataKey}
          hide={xHide}
          // angle="-90"
          // mirror
          // tickCount={5}
          axisLine={false}
          // tickMargin="-30"
          label={{
            // value: 'page',
            angle: 0,
            offset: 0,
            position: 'insideBottomRight',
          }}
          tickLine={false}
          tickFormatter={xAxisFormater}
          // scale="band"
        />
        {showReferenceline && <ReferenceLine y={0} />}
        <YAxis
          axisLine={false}
          fill="#999AAE"
          color="#999AAE"
          tickLine={false}
          label={renderCustomBarLabel}
          tickCount={20}
          tickFormatter={yAxisFormater}
        />
        {showLegend && <Legend content={legendContent} />}

        {showTooltip && <Tooltip content={tooltipContent} />}
        {keyArray &&
          keyArray?.length &&
          keyArray?.map((b, k) => (
            <Bar
              key={`cell-${b}`}
              dataKey={b}
              shape={<Rectangle radius={[5, 5, 5, 5]} />}
              label={
                showYLabel && {
                  position: 'insideTop',
                  angle: '-90',
                  fill: '#fff',
                  offset: 25,
                  content: '',
                }
              }
              fill={color[k % 20]}
            />
          ))}
        {singleKey && (
          <Bar
            dataKey={singleKey}
            shape={<Rectangle radius={[5, 5, 5, 5]} />}
            label={
              showYLabel && {
                position: 'insideTop',
                angle: '-90',
                fill: '#fff',
                offset: 25,
                content: '',
              }
            }
            fill={color[data.length % 20]}
            barSize={80}
          >
            {data?.map((v, i) => (
              <Cell key={`cell-${i}`} fill={color[i % color.length]} />
            ))}
          </Bar>
        )}
      </ReactBarChart>
    </ResponsiveContainer>
  );
}

BiDirectionalBarChart.propTypes = {
  data: PropTypes.array.isRequired,
  keyArray: PropTypes.array.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.array,
  xDataKey: PropTypes.string,
  showLegend: PropTypes.bool,
  xHide: PropTypes.bool,
  tooltipContent: PropTypes.element,
  singleKey: PropTypes.string,
  legendContent: PropTypes.element,
  showYLabel: PropTypes.bool,
  yAxisFormater: PropTypes.node,
  xAxisFormater: PropTypes.node,
  showTooltip: PropTypes.bool,
  showReferenceline: PropTypes.bool,
};

BiDirectionalBarChart.defaultProps = {
  width: 500,
  height: 300,
  color: [
    '#1f77b4',
    '#ff7f0e',
    '#2ca02c',
    '#d62728',
    '#9467bd',
    '#8c564b',
    '#e377c2',
    '#7f7f7f',
    '#bcbd22',
    '#17becf',
  ],
  xDataKey: 'name',
  showLegend: false,
  xHide: false,
  tooltipContent: null,
  singleKey: '',
  legendContent: null,
  showYLabel: false,
  xAxisFormater: null,
  yAxisFormater: null,
  showTooltip: true,
  showReferenceline: true,
};

export default BiDirectionalBarChart;

const getPath = (x, y, width, height) => {
  return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${
    y + height / 3
  } 
  ${x + width / 2}, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + width} ${
    x + width
  }, ${y + height}
  Z`;
};

function RoundedBar({ fill, x, y, width, height }) {
  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
}

RoundedBar.propTypes = {
  fill: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};
