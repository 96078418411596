import { ReactComponent as homeIcon } from 'assets/home.svg';
import { ReactComponent as campaignIcon } from 'assets/campaign.svg';
import { ReactComponent as warroomIcon } from 'assets/svg/warrroom.svg';
import { ReactComponent as directoryIcon } from 'assets/directory-new.svg';
import { ReactComponent as voterIcon } from 'assets/voters.svg';
import { ReactComponent as securityIcon } from 'assets/svg/security_icon.svg';
import { ReactComponent as callCenterIcon } from 'assets/CallGroup.svg';
import { ReactComponent as PartyIcon } from 'assets/flag.svg';
import { ReactComponent as canvasserIcon } from 'assets/person.svg';
import { ReactComponent as electorateIcon } from 'assets/electorate.svg';
import { ReactComponent as instanceIcon } from 'assets/instance_icon.svg';
import { ReactComponent as databaseicon } from 'assets/databaseicon.svg';
import { ReactComponent as iIcon } from 'assets/iIcon.svg';
import { ReactComponent as off } from 'assets/off.svg';
import { ReactComponent as resultManagement } from 'assets/resultManagement.svg';
import { ReactComponent as agent } from 'assets/agenttt.svg';

import {
  AdminPaths,
  InboundCallGroupPaths,
  outboundCallGroupPaths,
  CampaignManagerPaths,
  LeadCallGroupPaths,
  CallGroupLeadOutboundPaths,
  SuperAdminPaths,
  CadidatePaths,
  ExecutivePaths,
  NationalExecutivePaths,
  SecurityMonitoringAdminPaths,
  SituationRoomAdminPaths,
} from 'constant/paths';

const ADMIN_SIDE_NAVS = [
  // { name: 'Dashboard', path: AdminPaths.ADMIN_DASHBOARD, icon: homeIcon },
  {
    name: 'Election',
    path: AdminPaths.ADMIN_CAMPAIGN,
    icon: campaignIcon,
  },
  {
    name: 'Call Group',
    path: AdminPaths.ADMIN_CALL_GROUP,
    icon: callCenterIcon,
  },

  {
    name: 'Situation Report',
    path: AdminPaths.ADMIN_SITUATION_REPORT,
    icon: electorateIcon,
  },
  {
    name: 'Election Results',
    path: AdminPaths.ADMIN_RESULT_MANAGEMENT,
    icon: resultManagement,
  },
  {
    name: 'Intelligence',
    path: AdminPaths.ADMIN_INTELLIGENCE,
    icon: iIcon,
  },
  {
    name: 'Incidence',
    path: AdminPaths.ADMIN_INCIDENCE,
    icon: off,
  },
  {
    name: 'Security Report',
    path: AdminPaths.ADMIN_SECURITY_REPORT,
    icon: securityIcon,
  },
  {
    name: 'Voters 360',
    path: AdminPaths.ADMIN_VOTERS_360,
    icon: voterIcon,
  },
  { isHeader: true, name: 'DATABASE' },
  { name: 'Directory', path: AdminPaths.ADMIN_DIRECTORY, icon: directoryIcon },
  {
    name: 'Field Agent',
    path: AdminPaths.ADMIN_CANVASSER,
    icon: canvasserIcon,
  },
  { name: 'Party', path: AdminPaths.ADMIN_PARTY, icon: PartyIcon },

  {
    name: 'Electorate',
    path: AdminPaths.ADMIN_ELECTORATE,
    icon: electorateIcon,
  },
  // { name: 'Voters', path: AdminPaths.ADMIN_VOTER, icon: voterIcon },
];

const CALL_GROUP_AGENT_SIDE_INBOUND_NAVS = [
  {
    name: 'Dashboard',
    path: InboundCallGroupPaths.CALL_GROUP_DASHBOARD,
    icon: homeIcon,
  },
  {
    name: 'Security Report',
    path: InboundCallGroupPaths.CALL_GROUP_SECURITY_REPORT,
    icon: securityIcon,
  },
  {
    name: 'Work Station',
    path: InboundCallGroupPaths.CALL_GROUP_WORK_STATION,
    icon: voterIcon,
  },
  // {
  //   name: 'Log',
  //   path: InboundCallGroupPaths.CALL_GROUP_LOG,
  //   icon: callCenterIcon,
  // },
];

const CALL_GROUP_AGENT_SIDE_OUTBOUND_NAVS = [
  {
    name: 'Dashboard',
    path: outboundCallGroupPaths.CALL_GROUP_DASHBOARD,
    icon: homeIcon,
  },
  {
    name: 'Work Station',
    path: outboundCallGroupPaths.CALL_GROUP_WORK_STATION,
    icon: voterIcon,
  },
  {
    name: 'Log',
    path: outboundCallGroupPaths.CALL_GROUP_LOG,
    icon: databaseicon,
  },
  {
    name: 'Security Report',
    path: outboundCallGroupPaths.CALL_GROUP_SECURITY_REPORT,
    icon: securityIcon,
  },
  { isHeader: true, name: 'DATABASE' },
  {
    name: 'Directory',
    path: outboundCallGroupPaths.CALL_GROUP_DIRECTORY,
    icon: directoryIcon,
  },
  {
    name: 'Agent Management',
    path: outboundCallGroupPaths.CALL_GROUP_AGENT_MANAGEMENT,
    icon: canvasserIcon,
  },
  {
    name: 'Field Agent',
    path: outboundCallGroupPaths.CALL_GROUP_CANVASSER,
    icon: canvasserIcon,
  },
  {
    name: 'Party',
    path: outboundCallGroupPaths.CALL_GROUP_PARTY,
    icon: PartyIcon,
  },
];

const CALL_GROUP_LEAD_SIDE_NAVS = [
  {
    name: 'Dashboard',
    path: LeadCallGroupPaths.CALL_GROUP_DASHBOARD,
    icon: homeIcon,
  },
  {
    name: 'Work Station',
    path: LeadCallGroupPaths.CALL_GROUP_WORK_STATION,
    icon: voterIcon,
  },
  {
    name: 'Agents',
    path: LeadCallGroupPaths.CALL_GROUP_AGENT,
    icon: canvasserIcon,
  },
  {
    name: 'Logs',
    path: LeadCallGroupPaths.CALL_GROUP_LOG,
    icon: callCenterIcon,
  },
  {
    name: 'Security Report',
    path: LeadCallGroupPaths.CALL_GROUP_SECURITY_REPORT,
    icon: securityIcon,
  },
];

const CAMPAIGN_MANAGER_SIDE_NAVS = [
  {
    name: 'Dashboard',
    path: CampaignManagerPaths.CAMPAIGN_MANAGER_DASHBOARD,
    icon: homeIcon,
  },
  // {
  //   name: 'War Room',
  //   path: CampaignManagerPaths.CAMPAIGN_MANAGER_WARROOM,
  //   icon: warroomIcon,
  // },
  // {
  //   name: 'War Room - State',
  //   path: CampaignManagerPaths.CAMPAIGN_MANAGER_WARROOM_STATE,
  //   icon: warroomIcon,
  // },

  {
    name: 'Poll',
    path: CampaignManagerPaths.CAMPAIGN_MANAGER_POLL,
    icon: campaignIcon,
  },
  {
    name: 'Call Group',
    path: CampaignManagerPaths.CAMPAIGN_MANAGER_CALL_GROUP,
    icon: callCenterIcon,
  },
  {
    name: 'Situation Report',
    path: CampaignManagerPaths.CAMPAIGN_MANAGER_SITUATION_REPORT,
    icon: electorateIcon,
  },
  {
    name: 'Election Results',
    path: CampaignManagerPaths.CAMPAIGN_MANAGER_ELECTION_RESULTS,
    icon: resultManagement,
  },
  {
    name: 'Intelligence',
    path: CampaignManagerPaths.CAMPAIGN_MANAGER_INTELLIGENCE,
    icon: iIcon,
  },
  {
    name: 'Incidence',
    path: CampaignManagerPaths.CAMPAIGN_MANAGER_INCIDENCE,
    icon: off,
  },
  {
    name: 'Security Report',
    path: CampaignManagerPaths.CAMPAIGN_MANAGER_SECURITY_REPORT,
    icon: securityIcon,
  },

  {
    name: 'Voters 360',
    path: CampaignManagerPaths.CAMPAIGN_MANAGER_VOTERS_360,
    icon: voterIcon,
  },
  { isHeader: true, name: 'DATABASE' },
  {
    name: 'Directory',
    path: CampaignManagerPaths.CAMPAIGN_MANAGER_DIRECTORY,
    icon: directoryIcon,
  },
  {
    name: 'Field Agent',
    path: CampaignManagerPaths.CAMPAIGN_MANAGER_CANVASSER,
    icon: canvasserIcon,
  },
  {
    name: 'Party',
    path: CampaignManagerPaths.CAMPAIGN_MANAGER_PARTY,
    icon: PartyIcon,
  },
  {
    name: 'Electorate',
    path: CampaignManagerPaths.CAMPAIGN_MANAGER_ELECTORATE,
    icon: electorateIcon,
  },
];

const CALL_GROUP_LEAD_OUTBOUND_SIDE_NAVS = [
  {
    name: 'Dashboard',
    path: CallGroupLeadOutboundPaths.CALL_GROUP_LEAD_DASHBOARD,
    icon: homeIcon,
  },
  {
    name: 'Polls',
    path: CallGroupLeadOutboundPaths.CALL_GROUP_LEAD_WORKSTATION,
    icon: warroomIcon,
  },
  {
    name: 'Agents',
    path: CallGroupLeadOutboundPaths.CALL_GROUP_LEAD_AGENTS,
    icon: agent,
  },
  {
    name: 'Log',
    path: CallGroupLeadOutboundPaths.CALL_GROUP_LEAD_LOG,
    icon: databaseicon,
  },
  {
    name: 'Security Report',
    path: CallGroupLeadOutboundPaths.CALL_GROUP_LEAD_SECURITY_REPORT,
    icon: securityIcon,
  },
  { isHeader: true, name: 'DATABASE' },
  {
    name: 'Directory',
    path: CallGroupLeadOutboundPaths.CALL_GROUP_LEAD_DIRECTORY,
    icon: directoryIcon,
  },
  {
    name: 'Field Agent',
    path: CallGroupLeadOutboundPaths.CALL_GROUP_LEAD_CANVASSER,
    icon: canvasserIcon,
  },
  {
    name: 'Party',
    path: CallGroupLeadOutboundPaths.CALL_GROUP_LEAD_PARTY,
    icon: PartyIcon,
  },
  {
    name: 'Agent Management',
    path: CallGroupLeadOutboundPaths.CALL_GROUP_AGENT_MANAGEMENT,
    icon: canvasserIcon,
  },
];

const SUPER_ADMIN_SIDE_NAVS = [
  {
    name: 'Instance',
    path: SuperAdminPaths.SUPER_ADMIN_INSTANCE,
    icon: instanceIcon,
  },
];

const CANDIDATE_SIDE_NAVS = [
  {
    name: 'Dashboard',
    path: CadidatePaths.CANDIDATE_DASHBOARD,
    icon: homeIcon,
  },
  {
    name: 'Situation Report',
    path: CadidatePaths.CANDIDATE_SITUATION_REPORT,
    icon: electorateIcon,
  },
  {
    name: 'Election Results',
    path: CadidatePaths.CANDIDATE_ELECTION_RESULTS,
    icon: resultManagement,
  },
  {
    name: 'Intelligence',
    path: CadidatePaths.CANDIDATE_INTELLIGENCE,
    icon: iIcon,
  },
  {
    name: 'Incidence',
    path: CadidatePaths.CANDIDATE_INCIDENCE,
    icon: off,
  },
  {
    name: 'Security Report',
    path: CadidatePaths.CANDIDATE_SECURITY_REPORT,
    icon: securityIcon,
  },
  {
    name: 'Voters 360',
    path: CadidatePaths.CANDIDATE_VOTERS_360,
    icon: voterIcon,
  },
  { isHeader: true, name: 'DATABASE' },
  {
    name: 'Directory',
    path: CadidatePaths.CANDIDATE_DIRECTORY,
    icon: directoryIcon,
  },
  {
    name: 'Field Agent',
    path: CadidatePaths.CANDIDATE_CANVASSER,
    icon: canvasserIcon,
  },
  { name: 'Party', path: CadidatePaths.CANDIDATE_PARTY, icon: PartyIcon },

  {
    name: 'Electorate',
    path: CadidatePaths.CANDIDATE_ELECTORATE,
    icon: electorateIcon,
  },
];

const EXECUTIVE_SIDE_NAVS = [
  {
    name: 'Dashboard',
    path: ExecutivePaths.EXECUTIVE_DASHBOARD,
    icon: homeIcon,
  },
  {
    name: 'Security Report',
    path: ExecutivePaths.EXECUTIVE_SECURITY_REPORT,
    icon: securityIcon,
  },
  {
    name: 'Directory',
    path: ExecutivePaths.EXECUTIVE_DIRECTORY,
    icon: homeIcon,
  },
];

const NATIONAL_EXECUTIVE_SIDE_NAVS = [
  {
    name: 'Dashboard',
    path: NationalExecutivePaths.NATIONAL_EXECUTIVE_DASHBOARD,
    icon: homeIcon,
  },
  {
    name: 'Security Report',
    path: NationalExecutivePaths.NATIONAL_EXECUTIVE_SECURITY_REPORT,
    icon: securityIcon,
  },
  {
    name: 'Directory',
    path: NationalExecutivePaths.NATIONAL_EXECUTIVE_DIRECTORY,
    icon: homeIcon,
  },
];

const SECURITY_MONITORING_ADMIN_SIDE_NAVS = [
  {
    name: 'Dashboard',
    path: SecurityMonitoringAdminPaths.SECURITY_MONITORING_DASHBOARD,
    icon: homeIcon,
  },
  // {
  //   name: 'War Room',
  //   path: SecurityMonitoringAdminPaths.SECURITY_MONITORING_WARROOM,
  //   icon: warroomIcon,
  // },
  {
    name: 'Intelligence',
    path: SecurityMonitoringAdminPaths.SECURITY_MONITORING_INTELLIGENCE,
    icon: iIcon,
  },
  {
    name: 'Incidence',
    path: SecurityMonitoringAdminPaths.SECURITY_MONITORING_INCIDENCE,
    icon: off,
  },
  {
    name: 'Security Report',
    path: SecurityMonitoringAdminPaths.SECURITY_MONITORING_SECURITY_REPORT,
    icon: electorateIcon,
  },
  { isHeader: true, name: 'DATABASE' },
  {
    name: 'Directory',
    path: SecurityMonitoringAdminPaths.SECURITY_MONITORING_DIRECTORY,
    icon: directoryIcon,
  },
  {
    name: 'Agent Management',
    path: SecurityMonitoringAdminPaths.SECURITY_MONITORING_AGENTS,
    icon: canvasserIcon,
  },
  {
    name: 'Party',
    path: SecurityMonitoringAdminPaths.SECURITY_MONITORING_PARTY,
    icon: PartyIcon,
  },
];

const SITUATION_ROOM_ADMIN_SIDE_NAVS = [
  {
    name: 'Dashboard',
    path: SituationRoomAdminPaths.SITUATION_ROOM_DASHBOARD,
    icon: homeIcon,
  },
  // {
  //   name: 'War Room',
  //   path: SituationRoomAdminPaths.SITUATION_ROOM_WARROOM,
  //   icon: warroomIcon,
  // },
  {
    name: 'Situation Report',
    path: SituationRoomAdminPaths.SITUATION_ROOM_SITUATION_REPORT,
    icon: electorateIcon,
  },
  {
    name: 'Election Results',
    path: SituationRoomAdminPaths.SITUATION_ROOM_RESULT_MANAGEMENT,
    icon: resultManagement,
  },
  {
    name: 'Intelligence',
    path: SituationRoomAdminPaths.SITUATION_ROOM_INTELLIGENCE,
    icon: iIcon,
  },
  {
    name: 'Incidence',
    path: SituationRoomAdminPaths.SITUATION_ROOM_INCIDENCE,
    icon: off,
  },
  {
    name: 'Security Report',
    path: SituationRoomAdminPaths.SITUATION_ROOM_SECURITY_REPORT,
    icon: securityIcon,
  },
  { isHeader: true, name: 'DATABASE' },
  {
    name: 'Directory',
    path: SituationRoomAdminPaths.SITUATION_ROOM_DIRECTORY,
    icon: directoryIcon,
  },
  {
    name: 'Agent Management',
    path: SituationRoomAdminPaths.SITUATION_ROOM_AGENTS,
    icon: canvasserIcon,
  },
  {
    name: 'Party',
    path: SituationRoomAdminPaths.SITUATION_ROOM_PARTY,
    icon: PartyIcon,
  },
];
const SITUATION_ROOM_SIDE_NAVS = [
  {
    name: 'War Room',
    path: SituationRoomAdminPaths.SITUATION_ROOM_WARROOM,
    icon: warroomIcon,
  },
];
export {
  ADMIN_SIDE_NAVS,
  CAMPAIGN_MANAGER_SIDE_NAVS,
  CALL_GROUP_LEAD_SIDE_NAVS,
  CALL_GROUP_AGENT_SIDE_OUTBOUND_NAVS,
  CALL_GROUP_AGENT_SIDE_INBOUND_NAVS,
  CALL_GROUP_LEAD_OUTBOUND_SIDE_NAVS,
  SUPER_ADMIN_SIDE_NAVS,
  CANDIDATE_SIDE_NAVS,
  EXECUTIVE_SIDE_NAVS,
  NATIONAL_EXECUTIVE_SIDE_NAVS,
  SECURITY_MONITORING_ADMIN_SIDE_NAVS,
  SITUATION_ROOM_ADMIN_SIDE_NAVS,
  SITUATION_ROOM_SIDE_NAVS,
};
