import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { NotificationProvider } from 'context/notificationProvider';
import { LayoutBGProvider } from 'hooks/useLayoutBg';

import theme from '../theme/index';
import { NotificationContextProvider } from './notificationContextProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 1000,
    },
    mutations: {
      throwOnError: false,
      useErrorBoundary: false,
    },
  },
});
function AppProviders({ children }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <NotificationProvider>
        <NotificationContextProvider>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <LayoutBGProvider>{children}</LayoutBGProvider>
            </ThemeProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </NotificationContextProvider>
      </NotificationProvider>
    </LocalizationProvider>
  );
}

AppProviders.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProviders;
