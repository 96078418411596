import { useQuery } from 'react-query';
import { getSlugInfo } from 'services/userService';
import keys from 'config';

function useTenantDetails() {
  const { data, isLoading } = useQuery(
    [`useFetchSIngleCallGroup`],
    () => getSlugInfo({ slug: keys.slugName }),
    {
      enabled: !!keys.slugName,
    }
  );

  return { data, isLoading };
}

export default useTenantDetails;
