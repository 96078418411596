/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import ProgressBar from 'react-customizable-progressbar';
import { pxToRem } from 'utils/formatFont';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

function DonutChart({ data, colors, radius }) {
  return (
    <Box>
      <ProgressBar
        radius={radius}
        progress={data.percentage}
        strokeColor={colors[0]}
        pointerRadius={0}
        pointerStrokeWidth={0}
        strokeWidth={8}
        trackStrokeWidth={8}
        trackStrokeColor="#FFB321"
        pointerStrokeColor="#7bcd5c"
        initialAnimationDelay={1000}
        initialAnimation
        trackTransition=".1s ease"
        transition="1s ease"
      >
        <BarWrapperStyle>
          <Typography
            variant="h3"
            color="text.main"
            fontSize={pxToRem(26)}
            sx={{
              '& span': {
                color: '#A7A9BC',
                fontSize: pxToRem(18),
                lineHeight: 1.5,
                fontWeight: 400,
              },
            }}
          >
            {data.percentage}
            <span>%</span>
          </Typography>
        </BarWrapperStyle>
      </ProgressBar>
    </Box>
  );
}

DonutChart.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    percentage: PropTypes.number,
    count: PropTypes.number,
  }).isRequired,
  colors: PropTypes.array,
  radius: PropTypes.number,
};

DonutChart.defaultProps = {
  colors: ['#5ACA75'],
  radius: 70,
};
const BarWrapperStyle = styled(Box)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: `0px 3px 6px rgba(0, 0, 0, 0.1)`,
  background: '#ffffff',
  width: '90px',
  height: '90px',
  borderRadius: '100%',
  display: 'grid',
  placeItems: 'center',
}));

export default DonutChart;
