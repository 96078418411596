/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  // CartesianGrid,
  Tooltip,
  // Legend,
  Cell,
  LabelList,
  // LabelList,
} from 'recharts';

function HorizontalBar({
  data,
  height,
  width,
  content,
  xHide,
  fillColours,
  dataKey,
  radius,
  barSize,
  tickLine,
  renderCustomizedLabel,
}) {
  return (
    <ComposedChart
      layout="vertical"
      width={width}
      height={height}
      data={data}
      margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
    >
      {/* <CartesianGrid stroke="#f5f5f5" /> */}
      <XAxis
        type="number"
        axisLine={xHide}
        style={{
          fontSize: '12',
          // fontFamily: 'Times New Roman',
          textAlign: 'left',
        }}
        tickLine={tickLine}
      />
      <YAxis
        dataKey="name"
        type="category"
        axisLine={xHide}
        style={{
          fontSize: '12',
          // fontFamily: 'Times New Roman',
          textAlign: 'left',
        }}
        tickLine={tickLine}
      />
      <Tooltip content={content} />
      {/* <Legend /> */}
      <Bar dataKey={dataKey} barSize={barSize} fill="#413ea0" radius={radius}>
        {/* <LabelList dataKey="name" position="left" fill="#000" /> */}
        {data.map((entry, index) => (
          <Cell key={`cell-${entry}`} fill={fillColours[index]} />
        ))}
        {renderCustomizedLabel && (
          <LabelList
            dataKey={dataKey}
            content={renderCustomizedLabel}
            position="insideRight"
            style={{ fill: 'white' }}
          />
        )}
      </Bar>
    </ComposedChart>
  );
}

HorizontalBar.propTypes = {
  data: PropTypes.array.isRequired,
  height: PropTypes.number,
  content: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.any,
  ]),
  width: PropTypes.number,
  xHide: PropTypes.bool,
  tickLine: PropTypes.bool,
  dataKey: PropTypes.string.isRequired,
  fillColours: PropTypes.array,
  radius: PropTypes.number,
  barSize: PropTypes.number,
  renderCustomizedLabel: PropTypes.any,
};

HorizontalBar.defaultProps = {
  height: 400,
  width: 768,
  content: null,
  xHide: true,
  fillColours: [
    '#8F00FF',
    '#7F00FF',
    '#FFDB58',
    '#FFE338',
    '#FF6600',
    '#0000FF',
    '#FF0000',
    '#00008B',
    '#A020F0',
    '#32CD32',
  ],
  radius: 0,
  barSize: 20,
  tickLine: true,
  renderCustomizedLabel: null,
};

export default HorizontalBar;
