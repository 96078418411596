import React from 'react';
import PropTypes from 'prop-types';
import { Button, Box, Divider, Stack, Typography } from '@mui/material';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { numberWithCommas } from 'utils/numberformat';

import {
  StyledDot,
  StyledDrawer,
  StyledLabel,
  StyledRightTitle,
  StyledRightTitleValue,
  StyledTitleText,
} from './stateDetails.styles';

export default function StateDetailsDrawer({
  data,
  open,
  onClose,
  onExploreClicked,
}) {
  const pieData = [
    { name: 'VOTE FOR ME', value: data?.total_vote_for_me, color: '#5ACA75' },
    {
      name: 'VOTE AGAINST',
      value: data?.total_vote_against_me,
      color: '#FD9898',
    },
    { name: 'UNDECIDED', value: data?.total_undisclosed, color: '#E5E5EA' },
  ];

  const title = (
    <Stack direction="row" alignItems="center">
      <StyledDot />
      <StyledTitleText name={data?.state?.name.toLowerCase()}>
        {data?.state?.name.toLowerCase()}
      </StyledTitleText>
    </Stack>
  );
  return (
    <StyledDrawer
      disableHeaderShadow
      anchor="right"
      open={open}
      onClose={onClose}
      titleText={title}
      closeIconColor="#fff"
    >
      <Stack direction="row" px={6}>
        <Box flexGrow={10} pt={4} sx={{ borderTop: '1px solid #fff' }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ borderBottom: '1px solid #fff' }}
            pb={4}
          >
            <StyledLabel>TOTAL VOTERS</StyledLabel>
            <Typography fontWeight={700}>
              {numberWithCommas(data?.total_voters)}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ borderBottom: '1px solid #fff' }}
            py={4}
          >
            <StyledLabel>TOTAL ENGAGED</StyledLabel>
            <Typography fontWeight={700}>
              {numberWithCommas(data?.total_engaged)}
            </Typography>
          </Stack>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            sx={{ borderBottom: '1px solid #fff' }}
            py={4}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              py={2}
            >
              <StyledLabel>WITH PVC</StyledLabel>
              <Stack direction="row" alignItems="center">
                <Typography>
                  {numberWithCommas(data?.total_with_pvc)}
                </Typography>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderColor: '#fff', mx: 4 }}
                />
                <Typography>
                  {parseFloat(data?.percent_with_pvc).toFixed(2)}%
                </Typography>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              py={2}
            >
              <StyledLabel>VOTING</StyledLabel>
              <Stack direction="row" alignItems="center">
                <Typography>
                  {numberWithCommas(data?.total_plan_to_vote)}
                </Typography>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderColor: '#fff', mx: 4 }}
                />
                <Typography>
                  {parseFloat(data?.percent_plan_to_vote).toFixed(2)}%
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            py={4}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              py={2}
            >
              <StyledLabel color="#5ACA75">VOTE FOR ME</StyledLabel>
              <Stack
                direction="row"
                alignItems="center"
                sx={{ color: '#5ACA75' }}
              >
                <Typography>
                  {numberWithCommas(data?.total_vote_for_me)}
                </Typography>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderColor: '#fff', mx: 4 }}
                />
                <Typography>
                  {parseFloat(data?.percent_vote_for_me).toFixed(2)}%
                </Typography>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              py={2}
            >
              <StyledLabel color="#FD9898">VOTE AGAINST</StyledLabel>
              <Stack
                direction="row"
                alignItems="center"
                sx={{ color: '#FD9898' }}
              >
                <Typography>
                  {numberWithCommas(data?.total_vote_against_me)}
                </Typography>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderColor: '#fff', mx: 4 }}
                />
                <Typography>
                  {parseFloat(data?.percent_vote_against_me).toFixed(2)}%
                </Typography>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              py={2}
            >
              <StyledLabel>UNDISCLOSED</StyledLabel>
              <Stack direction="row" alignItems="center">
                <Typography>
                  {numberWithCommas(data?.total_undisclosed)}
                </Typography>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderColor: '#fff', mx: 4 }}
                />
                <Typography>
                  {parseFloat(data?.percent_undisclosed).toFixed(2)}%
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <Box flexGrow={1} px={16}>
          <StyledRightTitle>
            PROJECTED NUMBER OF PEOPLE <br /> WHO WILL VOTE FOR YOU
          </StyledRightTitle>
          <StyledRightTitleValue>
            {numberWithCommas(data?.total_vote_for_me)}
          </StyledRightTitleValue>
          <ResponsiveContainer width={240} height={240}>
            <PieChart>
              <Pie
                data={pieData}
                cx="50%"
                cy="50%"
                labelLine={false}
                // label={renderCustomizedLabel}
                innerRadius={30}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {pieData.map((entry, index) => (
                  <Cell
                    key={`cell-${entry.name}`}
                    fill={pieData.map((v) => v.color)[index % pieData.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
          <Box>
            <Button
              color="info"
              sx={{
                color: (theme) => theme.palette.primary.main,
                fontWeight: 400,
                width: '198px',
                '&:hover': {
                  background: (theme) => theme.palette.info.light,
                },
              }}
              onClick={onExploreClicked}
            >
              EXPLORE DATA
            </Button>
          </Box>
        </Box>
      </Stack>
    </StyledDrawer>
  );
}

StateDetailsDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onExploreClicked: PropTypes.func,
  data: PropTypes.objectOf({}),
};

StateDetailsDrawer.defaultProps = {
  open: false,
  onClose: () => {},
  onExploreClicked: () => {},
  data: {},
};
