import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link, useNavigate, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Badge from '@mui/material/Badge';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Skeleton from '@mui/material/Skeleton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import useStyles from 'shared/Layout/styled.layout';
import { ReactComponent as BrandLogo } from 'assets/copyright_logo_light.svg';
import logoPOllEasy from 'assets/defaultBrand.svg';
import Auth from 'utils/Auth';
import {
  ADMIN_SIDE_NAVS,
  CALL_GROUP_AGENT_SIDE_INBOUND_NAVS,
  CALL_GROUP_AGENT_SIDE_OUTBOUND_NAVS,
  CALL_GROUP_LEAD_SIDE_NAVS,
  CAMPAIGN_MANAGER_SIDE_NAVS,
  CALL_GROUP_LEAD_OUTBOUND_SIDE_NAVS,
  SUPER_ADMIN_SIDE_NAVS,
  CANDIDATE_SIDE_NAVS,
  EXECUTIVE_SIDE_NAVS,
  NATIONAL_EXECUTIVE_SIDE_NAVS,
  SECURITY_MONITORING_ADMIN_SIDE_NAVS,
  SITUATION_ROOM_ADMIN_SIDE_NAVS,
  SITUATION_ROOM_SIDE_NAVS,
} from 'constant/sidenav';
import { useQueryClient, useMutation } from 'react-query';
import nameInitial from 'utils/nameInitial';
import useAuth from 'hooks/useAuth';
import { underSCoreCapitalizeWord } from 'utils/stringTranform';
import useTenantDetails from 'hooks/useTenantDetails';
import keys from 'config';
import NotificationMenu from 'modules/CallGroupLeadOutbound/components/Notification/NotificationMenu';
import useWebSocket from 'react-use-websocket';

// import options from './options';
import useNewUserAlert from 'hooks/useNewUserAlert';
import useFetchNotifications from 'hooks/queries/useNotification';
import { updateNotificationReadStatus } from 'modules/Admin/services/notifications';
import ContentWrapper from './contentWrapper';

const url = keys.slugUrl || '';

const PATHS_WITH_HIDDEN_SIDEBARS = [
  '/campaign-manager/war-room',
  '/campaign-manager/war-room-state',
  '/situation-room-admin/war-room',
  '/security-monitoring-admin/war-room',
  '/situation-room/war-room',
  '/state-situation-room',
  '/national-situation-room',
];

function Layout({ children }) {
  const classes = useStyles();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [anchor, setAnchor] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [bgProps, setBgProps] = React.useState({});
  // const [messageHistory, setMessageHistory] = useState([]);
  const { showNotification } = useNewUserAlert();
  const queryClient = useQueryClient();
  const markAsRead = useMutation(updateNotificationReadStatus);

  const bgValues = React.useMemo(() => ({ bgProps, setBgProps }), [bgProps]);
  const open = Boolean(anchorEl);
  const openPop = Boolean(anchor);
  const { data } = useTenantDetails();
  const token = Auth.getToken();

  const handleNoteClick = (event) => {
    setAnchor(event.currentTarget);
  };
  const handleNoteClose = () => {
    setAnchor(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMarkAllAsRead = () => {
    const params = { is_read: true };
    markAsRead.mutate(
      { params },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('useFetchNotifications');
          showNotification('Successfully marked as read', {
            type: 'success',
          });
        },
      }
    );
  };

  const { notifications } = useFetchNotifications();

  const fetchData = async () => {
    await queryClient.fetchInfiniteQuery(
      'useFetchNotifications',
      async (value) => {
        console.log(value, 'the value in here');
        // Perform any necessary operations or data manipulations with notifications
        console.log(notifications, 'the notif in here');
        // return notifications;
      }
    );
  };
  // Call the fetchData function when needed, such as in an event handler or useEffect
  React.useEffect(() => {
    fetchData();
  }, []);
  // set the websocket url

  const getSocketUrl = useCallback(() => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(`wss://api.py.polleasy.prunedge.org/ws/notification/`);
      }, 2000);
    });
  }, []);

  const { readyState, lastMessage, sendJsonMessage } = useWebSocket(
    getSocketUrl,
    {
      fromSocketIO: false, // boolean value
      queryParams: {
        // object containing query parameters
        token,
        // userId: 123,
      },
      // protocols: ['protocol1', 'protocol2'], // array of strings
      share: true, // boolean value
      onOpen: () => {
        // console.log('WebSocket connection opened', event);
      }, // function to be executed on WebSocket connection open event
      onClose: () => {}, // function to be executed on WebSocket connection close event
      onMessage: (event) => {
        const newData = JSON.parse(event.data);
        console.log(newData, 'the new data');
        // update the data based on the new data
        fetchData();
      }, // function to be executed on WebSocket message event
      onError: () => {}, // function to be executed on WebSocket error event
      onReconnectStop: (numAttempts) => {
        console.log(
          'WebSocket reconnect stopped after',
          numAttempts,
          'attempts'
        );
      }, // function to be executed when WebSocket reconnect stops
      shouldReconnect: () => {
        return true;
      }, // function to determine whether WebSocket should try to reconnect after a connection is lost
      reconnectInterval: (lastAttemptNumber) => {
        return lastAttemptNumber * 1000;
      }, // function to calculate the reconnect interval (in milliseconds)
      reconnectAttempts: 5, // number of times to attempt to reconnect after a connection is lost
      filter: (message) => {
        return message.data;
        // return message.data.startsWith('hello');
      }, // function to filter WebSocket messages
      retryOnError: true, // boolean value to retry on WebSocket errors
      // eventSourceOptions: { withCredentials: true }, // options object for EventSource, if using it
    }
  );
  console.log(readyState, lastMessage, 'readyState and the last message');

  const handleClickSendMessage = useCallback((value) => {
    // Call the sendJsonMessage function with the provided value
    sendJsonMessage(value);
    // You can also include additional logic here if needed
    fetchData();
  }, []);

  const handleButtonClick = (id) => {
    const myObject = {};
    myObject.pk = id;
    handleClickSendMessage(myObject);
  };

  const SIDENAVS = {
    ADMIN: ADMIN_SIDE_NAVS,
    CallGroupLead: CALL_GROUP_LEAD_SIDE_NAVS,
    INBOUND_AGENT: CALL_GROUP_AGENT_SIDE_INBOUND_NAVS,
    OUTBOUND_AGENT: CALL_GROUP_AGENT_SIDE_OUTBOUND_NAVS,
    CAMPAIGN_MANAGER: CAMPAIGN_MANAGER_SIDE_NAVS,
    CALL_GROUP_LEAD: CALL_GROUP_LEAD_OUTBOUND_SIDE_NAVS,
    SUPERADMIN: SUPER_ADMIN_SIDE_NAVS,
    CANDIDATE: CANDIDATE_SIDE_NAVS,
    EXECUTIVE_USER:
      auth?.userObj?.level && auth?.userObj?.level === 'NATIONAL'
        ? NATIONAL_EXECUTIVE_SIDE_NAVS
        : EXECUTIVE_SIDE_NAVS,
    // NATIONAL_EXECUTIVE_USER: NATIONAL_EXECUTIVE_SIDE_NAVS,
    SECURITY_MONITORING_ADMIN: SECURITY_MONITORING_ADMIN_SIDE_NAVS,
    SITUATION_ROOM_ADMIN: SITUATION_ROOM_ADMIN_SIDE_NAVS,
    SITUATION_ROOM: SITUATION_ROOM_SIDE_NAVS,
  };
  return (
    <Box className={classes.root}>
      {!PATHS_WITH_HIDDEN_SIDEBARS.includes(pathname) && (
        <AppBar position="fixed" color="transparent">
          <Toolbar>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              sx={{ mx: 4 }}
            >
              <Grid item md={4}>
                <Box
                  sx={{
                    width: '100px',
                    height: '60px',
                  }}
                >
                  <img
                    src={data?.logo || logoPOllEasy}
                    alt="party logo"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                  />
                </Box>
              </Grid>
              <Grid item md={8}>
                <Grid
                  container
                  spacing={6}
                  justifyContent="flex-end"
                  alignItems="flex-start"
                >
                  {auth?.user === 'ADMIN' && (
                    <Grid item className="gear" mt={1}>
                      <Link to={`${url}/admin/settings`}>
                        <SettingsIcon />
                      </Link>
                    </Grid>
                  )}
                  <Grid item mt={1}>
                    <Badge
                      onClick={handleNoteClick}
                      variant={notifications ? 'dot' : undefined}
                      color={
                        ['ADMIN', 'CALL_GROUP_LEAD'].includes(auth?.user) &&
                        notifications?.find((info) => info?.is_read === false)
                          ? 'error'
                          : undefined
                      }
                      overlap={notifications ? 'circular' : undefined}
                      badgeContent="  "
                      sx={{ cursor: 'pointer' }}
                    >
                      <NotificationsNoneIcon />
                    </Badge>

                    {['ADMIN', 'CALL_GROUP_LEAD'].includes(auth?.user) && (
                      <Menu
                        anchorEl="right"
                        id="notification-menu"
                        open={openPop}
                        onClose={handleNoteClose}
                        // onClick={handleNoteClose}
                        float="right"
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            width: '617px',
                            // overflowY: 'hidden',
                            flexWrap: 'wrap',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 13,
                          },
                        }}
                        transformOrigin={{
                          horizontal: 'right',
                          vertical: 'top',
                        }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                      >
                        <Box
                          sx={{
                            minHeight: '50px',
                            width: '100%',
                            padding: '16px',
                            boxShadow: 'inset 0px -2.5px 0px #E5E5EA',
                            position: 'fixed',
                            color: '#FFFFFF',
                          }}
                        >
                          <Stack
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            direction="row"
                          >
                            <Typography
                              sx={{
                                fontSize: '14px',
                                fontWeight: 'bold',
                                color: '#393A4A',
                              }}
                            >
                              Notification
                            </Typography>
                            <Typography
                              onClick={handleMarkAllAsRead}
                              sx={{
                                fontSize: '12px',
                                fontWeight: '500',
                                color: '#0050C8',
                                cursor: 'pointer',
                              }}
                            >
                              Mark all as read
                            </Typography>
                          </Stack>
                        </Box>
                        <Box minHeight="45px">
                          <NotificationMenu
                            data={notifications}
                            onClick={handleButtonClick}
                          />
                        </Box>
                      </Menu>
                    )}
                  </Grid>

                  <Grid item>
                    <Avatar
                      onClick={handleClick}
                      sx={{ width: 30, height: 30, cursor: 'pointer' }}
                    >
                      <Typography variant="body2">
                        {nameInitial(
                          `${auth?.userObj?.firstname} ${auth?.userObj?.lastname}`
                        )}
                      </Typography>
                    </Avatar>
                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={open}
                      onClose={handleClose}
                      onClick={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          width: 300,
                          overflow: 'visible',
                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          mt: 1.5,
                          '& .MuiAvatar-root': {
                            width: 30,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'blue',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{
                        horizontal: 'right',
                        vertical: 'top',
                      }}
                      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    >
                      <MenuItem>
                        <Stack direction="row" spacing={1}>
                          <Avatar
                            onClick={handleClick}
                            sx={{ width: 40, height: 40, bgcolor: 'blue' }}
                          >
                            <Typography variant="button">
                              {nameInitial(
                                `${auth?.userObj?.firstname} ${auth?.userObj?.lastname}`
                              )}
                            </Typography>
                          </Avatar>
                          <Box>
                            <Typography sx={{ mb: 0 }} variant="body2">
                              {`${auth?.userObj?.firstname} ${auth?.userObj?.lastname}`}
                            </Typography>
                            <Typography
                              sx={{ fontSize: 10, mt: 0 }}
                              variant="caption"
                            >
                              {auth?.userObj?.email || '--'}
                            </Typography>
                          </Box>
                        </Stack>
                      </MenuItem>
                      <Divider />
                      <MenuItem>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <Typography variant="body2">Role</Typography>
                          <Typography variant="inherit">
                            {underSCoreCapitalizeWord(auth.user)}
                          </Typography>
                        </Box>
                      </MenuItem>
                      <Divider />
                      <MenuItem>
                        <ListItemIcon>
                          <ModeEditOutlineOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit"> Edit Profile</Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          auth.signOut();
                          navigate('/');
                        }}
                      >
                        <ListItemIcon>
                          <PowerSettingsNewOutlinedIcon
                            color="warning"
                            fontSize="small"
                          />
                        </ListItemIcon>
                        <Typography variant="inherit"> Logout</Typography>
                      </MenuItem>
                    </Menu>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      )}
      <Grid
        container
        spacing={0}
        sx={{ background: bgValues.bgProps.color || '#fff' }}
      >
        {!PATHS_WITH_HIDDEN_SIDEBARS.includes(pathname) && (
          <Grid item sm={2} sx={{ position: 'fixed', width: '40%' }}>
            <Box component="aside" className="sidenav">
              <Box sx={{ flexGrow: 1, pt: 20 }}>
                {!auth.user ? (
                  <Box sx={{ margin: '0px 1.25rem' }}>
                    {[0, 1, 2, 3, 4].map((value) => (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '.8em',
                        }}
                        key={value}
                      >
                        <Skeleton
                          variant="circular"
                          width={30}
                          height={30}
                          sx={{
                            background: '#e0e0e066',
                            mr: 2,
                          }}
                        />
                        <Skeleton
                          variant="text"
                          height={30}
                          width={140}
                          sx={{ background: '#e0e0e066' }}
                        />
                      </Box>
                    ))}
                  </Box>
                ) : (
                  // eslint-disable-next-line dot-notation
                  <Box sx={{ mt: 4 }}>
                    {SIDENAVS[auth.user]?.map((sidenav) =>
                      sidenav.isHeader ? (
                        <Typography
                          sx={{
                            ml: 5,
                            mt: 4,
                            color: '#A7A9BC',
                          }}
                          variant="body1"
                          key={sidenav.name}
                        >
                          {sidenav.name}
                        </Typography>
                      ) : (
                        <NavLink
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '0.5rem',
                          }}
                          to={sidenav.path}
                          key={sidenav.name}
                        >
                          <sidenav.icon
                            stroke={
                              pathname === sidenav.path ? '#fff' : '#a5a9bc'
                            }
                            fill={
                              pathname === sidenav.path ? '#fff' : 'transparent'
                            }
                            style={{ width: '10%' }}
                          />
                          <Typography
                            variant="body1"
                            sx={{ paddingLeft: '10px' }}
                          >
                            {sidenav.name}
                          </Typography>
                        </NavLink>
                      )
                    )}
                  </Box>
                )}
              </Box>
              <Box className="setting" sx={{ pl: 6, pb: 1 }}>
                <Typography
                  variant="body1"
                  display="flex"
                  alignItems="center"
                  fontSize="14px"
                >
                  Powered By{' '}
                  <BrandLogo style={{ width: '35%', marginLeft: '.5em' }} />
                </Typography>
              </Box>
            </Box>
          </Grid>
        )}
        <ContentWrapper
          hideWrapper={PATHS_WITH_HIDDEN_SIDEBARS.includes(pathname)}
        >
          {children}
        </ContentWrapper>

        {/* <Grid item sm={10} sx={{ ml: '17%', mt: 15 }}>
          <LayoutContext.Provider value={bgValues}>
            <Box component="main" className="main">
              {children}
            </Box>
          </LayoutContext.Provider>
        </Grid> */}
      </Grid>
    </Box>
  );
}

export default Layout;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
