/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Bar,
  Rectangle,
  BarChart as ReactBarChart,
  ResponsiveContainer,
} from 'recharts';

function TinyBarChart({ data, width, height, barSize, ...rest }) {
  const filteredData = data;

  // const filteredData = data.map(({ rating, ...others }) => ({
  //   rating: +rating + 0.1,
  //   ...others,
  // }));

  return (
    <ResponsiveContainer height={height} width={width}>
      <ReactBarChart
        width={width}
        height={height}
        data={filteredData}
        {...rest}
      >
        <Bar
          dataKey={rest?.dataKey}
          shape={
            <Rectangle
              radius={[8, 8, 8, 8]}
              barCategoryGap="30%"
              fill="#0050C8"
            />
          }
          minPointSize={2}
          barSize={barSize}
        />
      </ReactBarChart>
    </ResponsiveContainer>
  );
}

TinyBarChart.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  barSize: PropTypes.number,
  dataKey: PropTypes.string,
};
TinyBarChart.defaultProps = {
  width: 200,
  height: 50,
  barSize: 7,
  dataKey: 'value',
};

export default TinyBarChart;
