import React, { useState, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';

export const ThemeContext = createContext(null);

// Define the provider component
export function CustomeThemeProvider({ children }) {
  const [isDarkMode, setTheme] = useState(true);

  useEffect(() => {
    const mode = JSON.parse(window.localStorage.getItem('mode'));
    console.log(mode);
    setTheme(mode);
  }, []);

  // Define a function to toggle the theme
  const toggleTheme = () => {
    console.log('---------');
    window.localStorage.setItem('mode', JSON.stringify(!isDarkMode));
    setTheme(!isDarkMode);
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    isDarkMode,
    toggleTheme,
  };

  // Return the provider with the context value and children
  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
}

CustomeThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
