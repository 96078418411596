import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import useStyles from 'shared/TabNav/styled.tabnav';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabNav({ navs, value, handleChange, disableValue, moreStyle }) {
  const classes = useStyles();

  return (
    <Box className={classes.root} sx={moreStyle}>
      <Tabs
        indicatorColor="primary"
        textextColor="primary"
        value={value}
        onChange={handleChange}
        aria-label="tab navigation"
      >
        {navs.map((nav, i) => (
          <Tab
            key={nav}
            label={nav}
            disabled={i === disableValue}
            disableRipple
            {...a11yProps(0)}
          />
        ))}
      </Tabs>
    </Box>
  );
}

export default TabNav;

TabNav.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node.isRequired),
    PropTypes.node.isRequired,
    PropTypes.any,
  ]).isRequired,
  value: PropTypes.number.isRequired,
  // eslint-disable-next-line
  moreStyle: PropTypes.object,
  navs: PropTypes.oneOfType([PropTypes.string, PropTypes.any]).isRequired,
  handleChange: PropTypes.func.isRequired,
  disableValue: PropTypes.oneOfType([PropTypes.number, PropTypes.any]),
};

TabNav.defaultProps = {
  disableValue: null,
};

TabNav.defaultProps = {
  moreStyle: {},
};
