/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/forbid-prop-types */
// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { ReactComponent as DeleteIcon } from 'assets/svg/times_circle.svg';
import { Controller } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Paper } from '@mui/material';

function AutoComplete({
  options,
  defaultValue,
  control,
  name,
  fixedOption,
  helperText,
  label,
  watch,
  errors,
  handleFetchNext,
  dataLength,
  hasMore,
  ...rest
}) {
  const fixedArray = [...fixedOption];

  const handleChange = (nwValue, onChange) => {
    if (fixedArray.length) {
      onChange([
        ...fixedArray,
        ...nwValue?.filter((v) => fixedArray?.indexOf(v) === -1),
      ]);
    } else {
      onChange(nwValue);
    }
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { ref, onChange, ...fields },
        fieldState: { error },
      }) => (
        <Autocomplete
          {...fields}
          // eslint-disable-next-line react/no-unstable-nested-components
          PaperComponent={(param) => (
            <InfiniteScroll
              // style={{ overflow: 'hidden' }}
              height={300}
              dataLength={dataLength}
              next={handleFetchNext}
              hasMore
              loader={
                <p style={{ textAlign: 'center', backgroundColor: '#fff' }}>
                  <b>Loading...</b>
                </p>
              }
              endMessage={
                <p style={{ textAlign: 'center', backgroundColor: '#fff' }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >
              <Paper {...param} />
            </InfiniteScroll>
          )}
          multiple
          options={options}
          ref={ref}
          // defaultValue={defaultValue || watch(name)}
          isOptionEqualToValue={(option, val) => option?.value === val?.value}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            return option?.label;
          }}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={option?.label}
                {...getTagProps({ index })}
                color="primary"
                deleteIcon={<DeleteIcon />}
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.light,
                  color: (theme) => theme.palette.text.main,
                  borderRadius: '4px',

                  '& .MuiChip-deleteIcon': {
                    color: (theme) => theme.palette.text.main,
                  },
                }}
                variant="filled"
                disabled={
                  fixedArray.length > 0 && fixedArray?.indexOf(option) !== -1
                }
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={label}
              fullWidth
              error={Boolean(error?.message)}
              helperText={error?.message || helperText}
            />
          )}
          onChange={(_, newValue) => handleChange(newValue, onChange)}
          {...rest}
        />
      )}
    />
  );
}

AutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  handleFetchNext: PropTypes.func,
  dataLength: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
      ]),
    })
  ),
  watch: PropTypes.arrayOf(PropTypes.string),
  errors: PropTypes.objectOf(PropTypes.string),
  helperText: PropTypes.string,
  label: PropTypes.string,
  hasMore: PropTypes.bool,
  control: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.string }),
  ]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.string,
  ]),
  fixedOption: PropTypes.array,
};

AutoComplete.defaultProps = {
  options: [],
  control: () => {},
  handleFetchNext: () => {},
  helperText: '',
  label: '',
  errors: {},
  hasMore: false,
  dataLength: 1000,
  watch: [''],
  defaultValue: [],
  fixedOption: [],
};

export default AutoComplete;
